import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ApiPostNoAuth, ApiGetNoAuth } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";

const initialValues = {
  email: "",
  password: "",
  role: "",
};

export default function Login() {
  const history = useHistory();
  // const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [Captch, setCaptch] = useState();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [pwd, setPwd] = useState("");
  const [Errorcaptch, setErrorcaptch] = useState();
  const recaptchaRef = React.createRef();
  // const [roleData, setRoleData] = useState([]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required!"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required!"),
    // role: Yup.string().required("Role is Required."),
  });

  useEffect(() => {
    // ApiGetNoAuth("role")
    //   .then((res) => {
    //     if (res.data && res.data.payload && res.data.payload.allRole) {
    //       setRoleData(res.data.payload.allRole);
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error.message);
    //   });
  }, []);

  const getInputClasses = (fieldname) => {
    // if (formik.touched[fieldname] && formik.errors[fieldname]) {
    //   return "is-invalid";
    // }
    // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    //   return "is-valid";
    // }
    // return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // if (Captch) {
        setLoading(true);
        const data = {
          email: values.email,
          password: values.password,
          userType: "admin"
          // role: values.role,
        };
        // const role = values.role;

        await ApiPostNoAuth("user/login", data)
          .then((res) => {
            console.log("reWWWWWWWWWWWs", res);
            if (res.data.result === -1) {
              toast.error(res.data.message);
              setErrorcaptch("");
            } else {
              if (
                res.data.payload.user.userType == "Admin" ||
                res.data.payload.user.userType == "admin" ||
                res.data.payload.user.userType == "ADMIN"
              ) {
                console.log("datafunctions", res.data.payload);
                authUtil.setToken(res.data.payload.token);
                userUtil.setUserInfo(res.data.payload);
                toast.success("You have signed in Successfully");
                window.location.reload();
                history.push("/dashboard");
                setLoading(false);
                setSubmitting(false);
                setErrorcaptch("");
              } else {
                toast.error(" Wrong Email or password ");
                setErrorcaptch("");
              }
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      // } else {
      //   setErrorcaptch("Please varify that you are not a robot");
      // }
    },
  });

  const handleCaptch = (value) => {
    console.log(value, "funswafhbfsfjhcbs");
    setCaptch(value);
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          Login Account
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ""
        )}

        {/* email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* password */}
        <div
          className="form-group relative-input fv-plugins-icon-container mb-4"
          style={{ marginBottom: "0.01rem" }}
        >
          <input
            placeholder="Password"
            type={isRevealPwd ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6`}
            value={pwd}
            name="password"
            onChange={(e) => setPwd(e.target.value)}
            {...formik.getFieldProps("password")}
          />
          {isRevealPwd ? (
            <div className="eye-icon-alignment" style={{ cursor: "pointer" }}>
              <i
                class="fa fa-eye"
                aria-hidden="true"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              ></i>
            </div>
          ) : (
            <div className="eye-icon-alignment" style={{ cursor: "pointer" }}>
              <i
                class="fas fa-eye-slash"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              ></i>
            </div>
          )}
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className="fv-plugins-message-container mb-3">
            <div className="fv-help-block">{formik.errors.password}</div>
          </div>
        ) : (
          ""
        )}
        {/* <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-lg form-control-solid ${getInputClasses(
              "role"
            )}`}
            name="role"
            {...formik.getFieldProps("role")}
          >
            <option>Select Role...</option>
            {roleData.map((record, i) => {
              return <option value={record._id}>{record.roleName}</option>;
            })}
            <option value="admin">Admin</option>
            <option value="bd_admin">BD-Admin</option>
            <option value="user">user</option>
            <option value="Partner">Affiliates</option>
          </select>
          {formik.touched.role && formik.errors.role ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.role}</div>
            </div>
          ) : null}
        </div> */}
        <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
          {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            forgot password?
          </Link> */}

          <form>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcM7f8cAAAAAGIAyBKbTByLO8ru2ioUWfUXVYh-"
              onChange={handleCaptch}
            />

            {/* <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcM7f8cAAAAAGIAyBKbTByLO8ru2ioUWfUXVYh-"
              onChange={handleCaptch}
            /> */}

            <p style={{ color: "red" }}>{Errorcaptch}</p>
          </form>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            // className={`btn btn-danger font-weight-bold px-9 py-4 my-3`}
            className={`btn font-weight-bold px-9 py-4 my-3`}
            style={{ backgroundColor: "#e61952", color: "white" }}
          >
            <span>Sign In</span>
          </button>
        </div>
        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div> */}
      </form>

      {/*end::Form*/}
    </div>
  );
}
